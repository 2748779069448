export const appointmentTime = [
    {
      label: "8:00 AM",
      value: "8:00 AM",
    },
    {
      label: "9:00 AM",
      value: "9:00 AM",
    },
    {
      label: "10:00 AM",
      value: "10:00 AM",
    },
    {
      label: "11:00 AM",
      value: "11:00 AM",
    },
    {
      label: "12:00 PM",
      value: "12:00 PM",
    },
    {
      label: "1:00 PM",
      value: "1:00 PM",
    },
    {
      label: "2:00 PM",
      value: "2:00 PM",
    },
    {
      label: "3:00 PM",
      value: "3:00 PM",
    },
    {
      label: "4:00 PM",
      value: "4:00 PM",
    },
  ];
  