/* AUTHENTICATION ACTIONS */
export const ATTEMPTING_LOGIN = "ATTEMPTING_LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";


/* REGISTRATION */
export const ATTEMPTING_REGISTER = 'ATTEMPTING_REGISTRATION'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';


/* SIDE MENU ACTIONS */
export const SIDE_MENU_LOADED = 'SIDE_MENU_LOADED';

// LOGOUT
export const ATTEMPTING_LOGOUT = 'ATTEMPTING_LOGOUT';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

/* ENROLLMENT ACTIONS */
export const SET_NEW_USER = 'SET_NEW_USER';
export const CLEAR_NEW_USER = 'CLEAR_NEW_USER';
export const SET_ENROLLMENT_MSG = 'SET_ENROLLMENT_MSG';

/* PROFILE ACTION */
export const SET_PROFILE = 'SET_PROFILE';
export const REFRESH_PROFILE = 'REFRESH_PROFILE';

/* ENROLLMENT */
export const IS_ON_CAP_OPTION_SELECTED = 'IS_ON_CAP_OPTION_SELECTED';
export const CAP_ID_CHANGED = 'CAP_ID_CHANGED';
export const LOAD_CAP_USER_TO_STATE = 'LOAD_CAP_USER_TO_STATE';
export const LOAD_REGISTRATION_FORM_DATA = 'LOAD_REGISTRATION_FORM_DATA';
export const LOAD_NEW_USER_TO_STATE = 'LOAD_NEW_USER_TO_STATE';


/* ALERTS */
export const ADD_ALERT = 'ADD_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const ADD_NEW_ALERT = 'ADD_NEW_ALERT';
/* APPLICATION */
export const IS_FIRST_PATIENT_APPLICATION = 'IS_FIRST_PATIENT_APPLICATION';
export const SET_PATIENT_APPLICATIONS = 'GET_PATIENT_APPLICATIONS';
export const SET_CURRENT_APPLICATION = 'SET_CURRENT_APPLICATION';
export const RESET_APPLICATION = 'RESET_APPLICATION';

/* POPUP ACTION */
export const ADD_NEW_POPUP = "ADD_NEW_POPUP";
export const DISMISS_POPUP = "DISMISS_POPUP";


export const TOGGLE_PAGE_SPINNER = 'TOGGLE_PAGE_SPINNER';
