import React from 'react'
import RoleContainer from './role.container';

function Role() {
    return (
        <>
            <RoleContainer />
        </>
    )
}

export default Role;